import React from 'react';
import logo from './logo.svg';
import './App.css';

import Cookies from "js-cookie";
import * as Realm from "realm-web";

function App() {
  const [user, setUser] = React.useState("");
  const login = React.useCallback(async () => {
    const app = new Realm.App("tasktracker-xqykw")
    let user = await app.logIn(Realm.Credentials.jwt(Cookies.get("CF_Authorization")!), true);
    console.info("Login: ", user);
    setUser(user.id);
  }, []);
  const logout = React.useCallback(async () => {
    Cookies.remove("CF_Authorization");
    window.location.reload();
  }, []);
  
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Here is DEV branch
        </p>
        <p>Current User: {user}</p>
        <button onClick={login}>Login</button>
        <button onClick={logout}>Logout</button>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
